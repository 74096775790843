<template>
  <div class="home__update-viewer" @click="closeViewer">
    <div class="inner-viewer" @click.stop="toggleText">
      <img v-if="updateId === 1" src="../assets/updates/update_01.jpg" >
      <img v-if="updateId === 2" src="../assets/updates/update_02.jpg" >
      <img v-if="updateId === 3" src="../assets/updates/update_03.jpg" >
      <img v-if="updateId === 4" src="../assets/updates/update_04.jpg" >
      <img v-if="updateId === 5" src="../assets/updates/update_05.jpg" >
      <video v-if="updateId === 6" src="../assets/updates/update_06.mp4" autoplay></video>
      <video v-if="updateId === 7" src="../assets/updates/update_07.mp4" autoplay></video>
      <video v-if="updateId === 8" src="../assets/updates/update_08.mp4" autoplay></video>
      <img v-if="updateId === 9" src="../assets/updates/update_08b.jpg" >
      <img v-if="updateId === 10" src="../assets/updates/update_17.jpg" >
      <img v-if="updateId === 11" src="../assets/updates/update_10.jpg" >
      <img v-if="updateId === 12" src="../assets/updates/update_11.jpg" >
      <img v-if="updateId === 13" src="../assets/updates/update_15.jpg" >
      <img v-if="updateId === 14" src="../assets/updates/update_16.jpg" >
      <img v-if="updateId === 15" src="../assets/updates/update_13.jpg" >
      <img v-if="updateId === 16" src="../assets/updates/update_12.jpg" >

      <div class="inner-viewer__text" :class="{'inner-viewer__text--show': showText, 'inner-viewer__text--hide': !showText}">
        <p v-if="updateId === 1">The WHO is WHO at Black Cave.<br/>The Black Cave-Team consists of three founders. Marina, Dominik and Felix.<br> We´re three game maniacs, who want to realize our own ideas of a game studio and games.</p>
        <p v-if="updateId === 2">Supported! By Mediengründerzentrum NRW ...<br>After a long journey with many quests and hard grinding, we finally earned the legendary MGZ-treasure.<br>LOOT: 10.000€, a lot of practical input and experienced coaches and mentors to support us in the startup phase.</p>
        <p v-if="updateId === 3">Haven – the flying capital of Antrum.</p>
        <p v-if="updateId === 4">Fynn – first main char of inAntrum<br/>Data: 21, female, fish – shark<br/>These were the first concept drawings of her.</p>
        <p v-if="updateId === 5">Sneakpeak: mood screenshot</p>
        <p v-if="updateId === 6">Our first short clip to show what inAntrum will look like.<br/>Just a simple small alley with some houses in the cave world Antrum.<br/>This is pre-alpha footage, so a lot can change in the future.</p>
        <p v-if="updateId === 7">Short timelapse to see how we build houses for inAntrum with v.1.0 of our custom-made tool for UE4.</p>
        <p v-if="updateId === 8">Fun with physics!</p>
      </div>
    </div>
    <div class="social-footer">
      <div class="social-footer__inner">
        <a href="https://www.facebook.com/blackcave.ent" title="facebook" target="_blank"><font-awesome-icon :icon="['fab', 'facebook']"></font-awesome-icon></a>
        <a href="https://www.instagram.com/blackcave_ent/" title="instagram" target="_blank"><font-awesome-icon :icon="['fab', 'instagram']"></font-awesome-icon></a>
        <a href="https://twitter.com/blackcave_ent" title="twitter" target="_blank"><font-awesome-icon :icon="['fab', 'twitter']"></font-awesome-icon></a>
        <a href="https://de.linkedin.com/company/black-cave" title="twitter" target="_blank"><font-awesome-icon :icon="['fab', 'linkedin']"></font-awesome-icon></a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UpdateViewer',
  props: {
    updateId: Number,
  },
  data: function() {
    return {
      showText: true,
    }
  },
  methods: {
    closeViewer: function () {
      this.$emit('closeViewer');
    },
    toggleText: function () {
      this.showText = !this.showText;
    }
  }
}
</script>

<style scoped>
.home__update-viewer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.75);
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 100px 16px 100px 16px;
}

.inner-viewer {
  height: auto;
  max-height: calc(100vh - 200px);
  position: relative;
}

.inner-viewer img,
.inner-viewer video {
  width: auto;
  max-width: 100%;
  max-height: calc(100vh - 200px);
}

.inner-viewer__text {
  position: absolute;
  bottom: 0;
  left: 0;
  height: auto;
  width: 100%;
  background-color: rgba(0,0,0,1);
  color: #fff;
  padding: 32px;
  transition: opacity ease-in-out 300ms;
}

.inner-viewer__text--hide {
  opacity: 0;
}

.inner-viewer__text--show {
  opacity: 0.85;
}

.inner-viewer__text--show:hover {
  opacity: 0.95;
  background-color: #000;
  cursor: pointer;
}

.social-footer {
  width: 100%;
  height: 80px;
  position: fixed;
  bottom: 0;
  left: 0;

  background-color: #000;
  padding: 32px 8px;
  z-index: 10;
  text-align: center;
  color: #fff;

  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.social-footer__inner {
  min-width: 200px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.social-footer__inner a {
  text-decoration: none;
  font-size: 32px;
  line-height: 32px;
  color: #969696;
}

</style>
