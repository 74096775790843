<template>
  <div class="contact">
    <Navigation />
    <section class="privacy">
      <h2 class="privacy-headline">Datenschutz</h2>
      <div class="content-box">
        <p class="privacy-text">
          Personenbezogene Daten (nachfolgend zumeist nur „Daten“ genannt)
          werden von uns nur im Rahmen der Erforderlichkeit sowie zum Zwecke der
          Bereitstellung eines funktionsfähigen und nutzerfreundlichen
          Internetauftritts, inklusive seiner Inhalte und der dort angebotenen
          Leistungen, verarbeitet.
        </p>
        <p class="privacy-text">
          Gemäß Art. 4 Ziffer 1. der Verordnung (EU) 2016/679, also der
          Datenschutz-Grundverordnung (nachfolgend nur „DSGVO“ genannt), gilt
          als „Verarbeitung“ jeder mit oder ohne Hilfe automatisierter Verfahren
          ausgeführter Vorgang oder jede solche Vorgangsreihe im Zusammenhang
          mit personenbezogenen Daten, wie das Erheben, das Erfassen, die
          Organisation, das Ordnen, die Speicherung, die Anpassung oder
          Veränderung, das Auslesen, das Abfragen, die Verwendung, die
          Offenlegung durch Übermittlung, Verbreitung oder eine andere Form der
          Bereitstellung, den Abgleich oder die Verknüpfung, die Einschränkung,
          das Löschen oder die Vernichtung.
        </p>
        <p class="privacy-text">
          Mit der nachfolgenden Datenschutzerklärung informieren wir Sie
          insbesondere über Art, Umfang, Zweck, Dauer und Rechtsgrundlage der
          Verarbeitung personenbezogener Daten, soweit wir entweder allein oder
          gemeinsam mit anderen über die Zwecke und Mittel der Verarbeitung
          entscheiden. Zudem informieren wir Sie nachfolgend über die von uns zu
          Optimierungszwecken sowie zur Steigerung der Nutzungsqualität
          eingesetzten Fremdkomponenten, soweit hierdurch Dritte Daten in
          wiederum eigener Verantwortung verarbeiten.
        </p>

        <br />
        <h3 class="privacy-sectionHeadline">
          I. Informationen über uns als Verantwortliche
        </h3>
        <br />
        <p class="privacy-text">
          Verantwortlicher Anbieter dieses Internetauftritts im
          datenschutzrechtlichen Sinne ist:
        </p>
        <p class="privacy-text">Siehe Impressum</p>

        <br />
        <h3 class="privacy-sectionHeadline">
          II. Rechte der Nutzer und Betroffenen
        </h3>
        <br />
        <p class="privacy-text">
          Mit Blick auf die nachfolgend noch näher beschriebene
          Datenverarbeitung haben die Nutzer und Betroffenen das Recht
        </p>
        <ul class="privacy-list">
          <li class="privacy-listItem">
            auf Bestätigung, ob sie betreffende Daten verarbeitet werden, auf
            Auskunft über die verarbeiteten Daten, auf weitere Informationen
            über die Datenverarbeitung sowie auf Kopien der Daten (vgl. auch
            Art. 15 DSGVO);
          </li>
          <li class="privacy-listItem">
            auf Berichtigung oder Vervollständigung unrichtiger bzw.
            unvollständiger Daten (vgl. auch Art. 16 DSGVO);
          </li>
          <li class="privacy-listItem">
            auf unverzügliche Löschung der sie betreffenden Daten (vgl. auch
            Art. 17 DSGVO), oder, alternativ, soweit eine weitere Verarbeitung
            gemäß Art. 17 Abs. 3 DSGVO erforderlich ist, auf Einschränkung der
            Verarbeitung nach Maßgabe von Art. 18 DSGVO;
          </li>
          <li class="privacy-listItem">
            auf Erhalt der sie betreffenden und von ihnen bereitgestellten Daten
            und auf Übermittlung dieser Daten an andere Anbieter/Verantwortliche
            (vgl. auch Art. 20 DSGVO);
          </li>
          <li class="privacy-listItem">
            auf Beschwerde gegenüber der Aufsichtsbehörde, sofern sie der
            Ansicht sind, dass die sie betreffenden Daten durch den Anbieter
            unter Verstoß gegen datenschutzrechtliche Bestimmungen verarbeitet
            werden (vgl. auch Art. 77 DSGVO).
          </li>
        </ul>
        <p class="privacy-text">
          Darüber hinaus ist der Anbieter dazu verpflichtet, alle Empfänger,
          denen gegenüber Daten durch den Anbieter offengelegt worden sind, über
          jedwede Berichtigung oder Löschung von Daten oder die Einschränkung
          der Verarbeitung, die aufgrund der Artikel 16, 17 Abs. 1, 18 DSGVO
          erfolgt, zu unterrichten. Diese Verpflichtung besteht jedoch nicht,
          soweit diese Mitteilung unmöglich oder mit einem unverhältnismäßigen
          Aufwand verbunden ist. Unbeschadet dessen hat der Nutzer ein Recht auf
          Auskunft über diese Empfänger.
        </p>
        <p class="privacy-text">
          Ebenfalls haben die Nutzer und Betroffenen nach Art. 21 DSGVO das
          Recht auf Widerspruch gegen die künftige Verarbeitung der sie
          betreffenden Daten, sofern die Daten durch den Anbieter nach Maßgabe
          von Art. 6 Abs. 1 lit. f) DSGVO verarbeitet werden. Insbesondere ist
          ein Widerspruch gegen die Datenverarbeitung zum Zwecke der
          Direktwerbung statthaft.
        </p>

        <br />
        <h3 class="privacy-sectionHeadline">
          III. Informationen zur Datenverarbeitung
        </h3>
        <br />
        <p class="privacy-text">
          Ihre bei Nutzung unseres Internetauftritts verarbeiteten Daten werden
          gelöscht oder gesperrt, sobald der Zweck der Speicherung entfällt, der
          Löschung der Daten keine gesetzlichen Aufbewahrungspflichten
          entgegenstehen und nachfolgend keine anderslautenden Angaben zu
          einzelnen Verarbeitungsverfahren gemacht werden.
        </p>

        <br />
        <h3 class="privacy-sectionHeadline">Serverdaten</h3>
        <br />
        <p class="privacy-text">
          Aus technischen Gründen, insbesondere zur Gewährleistung eines
          sicheren und stabilen Internetauftritts, werden Daten durch Ihren
          Internet-Browser an uns bzw. an unseren Webspace-Provider übermittelt.
          Mit diesen sog. Server-Logfiles werden u.a. Typ und Version Ihres
          Internetbrowsers, das Betriebssystem, die Website, von der aus Sie auf
          unseren Internetauftritt gewechselt haben (Referrer URL), die
          Website(s) unseres Internetauftritts, die Sie besuchen, Datum und
          Uhrzeit des jeweiligen Zugriffs sowie die IP-Adresse des
          Internetanschlusses, von dem aus die Nutzung unseres Internetauftritts
          erfolgt, erhoben.
        </p>
        <p class="privacy-text">
          Diese so erhobenen Daten werden vorrübergehend gespeichert, dies
          jedoch nicht gemeinsam mit anderen Daten von Ihnen.
        </p>
        <p class="privacy-text">
          Diese Speicherung erfolgt auf der Rechtsgrundlage von Art. 6 Abs. 1
          lit. f) DSGVO. Unser berechtigtes Interesse liegt in der Verbesserung,
          Stabilität, Funktionalität und Sicherheit unseres Internetauftritts.
        </p>
        <p class="privacy-text">
          Die Daten werden spätestens nach sieben Tage wieder gelöscht, soweit
          keine weitere Aufbewahrung zu Beweiszwecken erforderlich ist.
          Andernfalls sind die Daten bis zur endgültigen Klärung eines Vorfalls
          ganz oder teilweise von der Löschung ausgenommen.
        </p>

        <br />
        <h3 class="privacy-sectionHeadline">
          Kontaktanfragen / Kontaktmöglichkeit
        </h3>
        <br />
        <p class="privacy-text">
          Sofern Sie per Kontaktformular oder E-Mail mit uns in Kontakt treten,
          werden die dabei von Ihnen angegebenen Daten zur Bearbeitung Ihrer
          Anfrage genutzt. Die Angabe der Daten ist zur Bearbeitung und
          Beantwortung Ihre Anfrage erforderlich - ohne deren Bereitstellung
          können wir Ihre Anfrage nicht oder allenfalls eingeschränkt
          beantworten.
        </p>
        <p class="privacy-text">
          Rechtsgrundlage für diese Verarbeitung ist Art. 6 Abs. 1 lit. b)
          DSGVO.
        </p>
        <p class="privacy-text">
          Ihre Daten werden gelöscht, sofern Ihre Anfrage abschließend
          beantwortet worden ist und der Löschung keine gesetzlichen
          Aufbewahrungspflichten entgegenstehen, wie bspw. bei einer sich etwaig
          anschließenden Vertragsabwicklung.
        </p>

        <br />
        <h3 class="privacy-sectionHeadline">
          Verlinkung Social-Media über Grafik oder Textlink
        </h3>
        <br />
        <p class="privacy-text">
          Wir bewerben auf unserer Webseite auch Präsenzen auf den nachstehend
          aufgeführten sozialen Netzwerken. Die Einbindung erfolgt dabei über
          eine verlinkte Grafik des jeweiligen Netzwerks. Durch den Einsatz
          dieser verlinkten Grafik wird verhindert, dass es bei dem Aufruf einer
          Website, die über eine Social-Media-Bewerbung verfügt, automatisch zu
          einem Verbindungsaufbau zum jeweiligen Server des sozialen Netzwerks
          kommt, um eine Grafik des jeweiligen Netzwerkes selbst darzustellen.
          Erst durch einen Klick auf die entsprechende Grafik wird der Nutzer zu
          dem Dienst des jeweiligen sozialen Netzwerks weitergeleitet.
        </p>
        <p class="privacy-text">
          Nach der Weiterleitung des Nutzers werden durch das jeweilige Netzwerk
          Informationen über den Nutzer erfasst. Es kann hierbei nicht
          ausgeschlossen werden, dass eine Verarbeitung der so erhobenen&nbsp;
          Daten in den USA stattfindet.
        </p>
        <p class="privacy-text">
          Dies sind zunächst Daten wie IP-Adresse, Datum, Uhrzeit und besuchte
          Seite. Ist der Nutzer währenddessen in seinem Benutzerkonto des
          jeweiligen Netzwerks eingeloggt, kann der Netzwerk-Betreiber ggf. die
          gesammelten Informationen des konkreten Besuchs des Nutzers dem
          persönlichen Account des Nutzers zuordnen. Interagiert der Nutzer über
          einen „Teilen“-Button des jeweiligen Netzwerks, können diese
          Informationen in dem persönlichen Benutzerkonto des Nutzers
          gespeichert und ggf. veröffentlicht werden. Will der Nutzer
          verhindern, dass die gesammelten Informationen unmittelbar seinem
          Benutzerkonto zugeordnet werden, muss er sich vor dem Anklicken der
          Grafik ausloggen. Zudem besteht die Möglichkeit, das jeweilige
          Benutzerkonto entsprechend zu konfigurieren.
        </p>
        <p class="privacy-text">
          Folgende soziale Netzwerke werden in unsere Seite durch Verlinkung
          eingebunden:
        </p>

        <br />
        <h3 class="privacy-sectionHeadline">facebook</h3>
        <br />
        <p class="privacy-text">
          Facebook Ireland Limited, 4 Grand Canal Square, Dublin 2, Ireland, ein
          Tochterunternehmen <br />
          der Facebook Inc., 1601 S. California Ave., Palo Alto, CA 94304, USA.
        </p>
        <p class="privacy-text">
          Datenschutzerklärung:
          <a
            href="https://www.facebook.com/policy.php"
            target="_blank"
            rel="noopener"
            >https://www.facebook.com/policy.php</a
          >
        </p>

        <br />
        <h3 class="privacy-sectionHeadline">twitter</h3>
        <br />
        <p class="privacy-text">
          Twitter Inc., 795 Folsom St., Suite 600, San Francisco, CA 94107, USA
        </p>
        <p class="privacy-text">
          Datenschutzerklärung:
          <a href="https://twitter.com/privacy" target="_blank" rel="noopener"
            >https://twitter.com/privacy</a
          >
        </p>

        <br />
        <h3 class="privacy-sectionHeadline">YouTube</h3>
        <br />
        <p class="privacy-text">
          Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Irland,
          ein Tochterunternehmen <br />
          der Google LLC, 1600 Amphitheatre Parkway, Mountain View, CA 94043 USA
        </p>
        <p class="privacy-text">
          Datenschutzerklärung:
          <a
            href="https://policies.google.com/privacy"
            target="_blank"
            rel="noopener"
            >https://policies.google.com/privacy</a
          >
        </p>

        <br />
        <h3 class="privacy-sectionHeadline">LinkedIn</h3>
        <br />
        <p class="privacy-text">
          LinkedIn Ireland Unlimited Company, Wilton Plaza, Wilton Place, Dublin
          2, Irland, ein Tochterunternehmen <br />
          der LinkedIn Corporation, 1000 W. Maude Avenue, Sunnyvale, CA 94085
          USA.
        </p>
        <p class="privacy-text">
          Datenschutzerklärung:
          <a
            href="https://www.linkedin.com/legal/privacy-policy"
            target="_blank"
            rel="noopener"
            >https://www.linkedin.com/legal/privacy-policy</a
          >
        </p>

        <br />
        <h3 class="privacy-sectionHeadline">Instagram</h3>
        <br />
        <p class="privacy-text">
          Facebook Ireland Limited, 4 Grand Canal Square, Dublin 2, Ireland, ein
          Tochterunternehmen <br />
          der Facebook Inc., 1601 S. California Ave., Palo Alto, CA 94304, USA.
        </p>
        <p class="privacy-text">
          Datenschutzerklärung:
          <a
            href="https://help.instagram.com/519522125107875"
            target="_blank"
            rel="noopener"
            >https://help.instagram.com/519522125107875</a
          >
        </p>

        <br />
        <h3 class="privacy-sectionHeadline">Google Analytics</h3>
        <br />
        <p class="privacy-text">
          In unserem Internetauftritt setzen wir Google Analytics ein. Hierbei
          handelt es sich um einen Webanalysedienst der Google Ireland Limited,
          Gordon House, Barrow Street, Dublin 4, Irland, nachfolgend nur
          „Google“ genannt.
        </p>
        <p class="privacy-text">
          Der Dienst Google Analytics dient zur Analyse des Nutzungsverhaltens
          unseres Internetauftritts. Rechtsgrundlage ist Art. 6 Abs. 1 lit. f)
          DSGVO. Unser berechtigtes Interesse liegt in der Analyse, Optimierung
          und dem wirtschaftlichen Betrieb unseres Internetauftritts.
        </p>
        <p class="privacy-text">
          Nutzungs- und nutzerbezogene Informationen, wie bspw. IP-Adresse, Ort,
          Zeit oder Häufigkeit des Besuchs unseres Internetauftritts, werden
          dabei an einen Server von Google in den USA übertragen und dort
          gespeichert. Allerdings nutzen wir Google Analytics mit der sog.
          Anonymisierungsfunktion. Durch diese Funktion kürzt Google die
          IP-Adresse schon innerhalb der EU bzw. des EWR.
        </p>
        <p class="privacy-text">
          Die so erhobenen Daten werden wiederum von Google genutzt, um uns eine
          Auswertung über den Besuch unseres Internetauftritts sowie über die
          dortigen Nutzungsaktivitäten zur Verfügung zu stellen. Auch können
          diese Daten genutzt werden, um weitere Dienstleistungen zu erbringen,
          die mit der Nutzung unseres Internetauftritts und der Nutzung des
          Internets zusammenhängen.
        </p>
        <p class="privacy-text">
          Google gibt an, Ihre IP-Adresse nicht mit anderen Daten zu verbinden.
          Zudem hält Google unter
        </p>
        <p class="privacy-text">
          <a
            href="https://www.google.com/intl/de/policies/privacy/partners"
            target="_blank"
            rel="noopener"
            >https://www.google.com/intl/de/policies/privacy/partners</a
          >
        </p>
        <p class="privacy-text">
          weitere datenschutzrechtliche Informationen für Sie bereit, so bspw.
          auch zu den Möglichkeiten, die Datennutzung zu unterbinden.
        </p>
        <p class="privacy-text">Zudem bietet Google unter</p>
        <p class="privacy-text">
          <a
            href="https://tools.google.com/dlpage/gaoptout?hl=de"
            target="_blank"
            rel="noopener"
            >https://tools.google.com/dlpage/gaoptout?hl=de</a
          >
        </p>
        <p class="privacy-text">
          ein sog. Deaktivierungs-Add-on nebst weiteren Informationen hierzu an.
          Dieses Add-on lässt sich mit den gängigen Internet-Browsern
          installieren und bietet Ihnen weitergehende Kontrollmöglichkeit über
          die Daten, die Google bei Aufruf unseres Internetauftritts erfasst.
          Dabei teilt das Add-on dem JavaScript (ga.js) von Google Analytics
          mit, dass Informationen zum Besuch unseres Internetauftritts nicht an
          Google Analytics übermittelt werden sollen. Dies verhindert aber
          nicht, dass Informationen an uns oder an andere Webanalysedienste
          übermittelt werden. Ob und welche weiteren Webanalysedienste von uns
          eingesetzt werden, erfahren Sie natürlich ebenfalls in dieser
          Datenschutzerklärung.
        </p>

        <br />
        <h3 class="privacy-sectionHeadline">YouTube</h3>
        <br />
        <p class="privacy-text">
          In unserem Internetauftritt setzen wir YouTube ein. Hierbei handelt es
          sich um ein Videoportal der YouTube LLC., 901 Cherry Ave., 94066 San
          Bruno, CA, USA, nachfolgend nur „YouTube“ genannt.
        </p>
        <p class="privacy-text">
          YouTube ist ein Tochterunternehmen der Google Ireland Limited, Gordon
          House, Barrow Street, Dublin 4, Irland, nachfolgend nur „Google“
          genannt.
        </p>
        <p class="privacy-text">
          Wir nutzen YouTube im Zusammenhang mit der Funktion „Erweiterter
          Datenschutzmodus“, um Ihnen Videos anzeigen zu können. Rechtsgrundlage
          ist Art. 6 Abs. 1 lit. f) DSGVO. Unser berechtigtes Interesse liegt in
          der Qualitätsverbesserung unseres Internetauftritts. Die Funktion
          „Erweiterter Datenschutzmodus“ bewirkt laut Angaben von YouTube, dass
          die nachfolgend noch näher bezeichneten Daten nur dann an den Server
          von YouTube übermittelt werden, wenn Sie ein Video auch tatsächlich
          starten.
        </p>
        <p class="privacy-text">
          Ohne diesen „Erweiterten Datenschutz“ wird eine Verbindung zum Server
          von YouTube in den USA hergestellt, sobald Sie eine unserer
          Internetseiten, auf der ein YouTube-Video eingebettet ist, aufrufen.
        </p>
        <p class="privacy-text">
          Diese Verbindung ist erforderlich, um das jeweilige Video auf unserer
          Internetseite über Ihren Internet-Browser darstellen zu können. Im
          Zuge dessen wird YouTube zumindest Ihre IP-Adresse, das Datum nebst
          Uhrzeit sowie die von Ihnen besuchte Internetseite erfassen und
          verarbeiten. Zudem wird eine Verbindung zu dem Werbenetzwerk
          „DoubleClick“ von Google hergestellt.
        </p>
        <p class="privacy-text">
          Sollten Sie gleichzeitig bei YouTube eingeloggt sein, weist YouTube
          die Verbindungsinformationen Ihrem YouTube-Konto zu. Wenn Sie das
          verhindern möchten, müssen Sie sich entweder vor dem Besuch unseres
          Internetauftritts bei YouTube ausloggen oder die entsprechenden
          Einstellungen in Ihrem YouTube-Benutzerkonto vornehmen.
        </p>
        <p class="privacy-text">
          Zum Zwecke der Funktionalität sowie zur Analyse des Nutzungsverhaltens
          speichert YouTube dauerhaft Cookies über Ihren Internet-Browser auf
          Ihrem Endgerät. Falls Sie mit dieser Verarbeitung nicht einverstanden
          sind, haben Sie die Möglichkeit, die Speicherung der Cookies durch
          eine Einstellung in Ihrem Internet-Browsers zu verhindern. Nähere
          Informationen hierzu finden Sie vorstehend unter „Cookies“.
        </p>
        <p class="privacy-text">
          Weitergehende Informationen über die Erhebung und Nutzung von Daten
          sowie Ihre diesbezüglichen Rechte und Schutzmöglichkeiten hält Google
          in den unter
        </p>
        <p class="privacy-text">
          <a
            href="https://policies.google.com/privacy"
            target="_blank"
            rel="noopener"
            >https://policies.google.com/privacy</a
          >
        </p>
        <p class="privacy-text">abrufbaren Datenschutzhinweisen bereit.</p>
        <p class="privacy-text">
          Quelle: 
          <a
            href="https://www.ratgeberrecht.eu/datenschutz/datenschutzerklaerung-generator-dsgvo.html"
            target="_blank"
            rel="noopener"
            >Anwaltskanzlei Weiß &amp; Partner</a
          >
        </p>
      </div>
    </section>
    <Footer />
  </div>
</template>

<script>
import Navigation from "@/components/Navigation";
import Footer from "@/components/Footer";

export default {
  name: "Privacy",
  components: {
    Footer,
    Navigation,
  },
};
</script>

<style>
.content-box {
  background-color: #f6f6f6;
  padding: 32px 64px;
}

.content-box--fake {
  margin: 0 16px;
  padding: 8px 0;
}

.privacy {
  max-width: 1280px;
  margin: 32px auto;
  padding: 0 16px;
}

.privacy-headline {
  color: #000000;
  font-weight: 400;
  font-size: 24px;
  line-height: 24px;
  margin-bottom: -4px;
  margin-left: 16px;
}

.privacy-sectionHeadline {
  color: #000000;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  margin-bottom: -4px;
}

.privacy-text {
  font-size: 18px;
  font-weight: 100;
  line-height: 24px;
  text-align: left;
  margin-bottom: 16px;
}

.privacy-list {
  margin-left: 32px;
  margin-bottom: 16px;
}

.privacy-listItem {
  font-size: 18px;
  font-weight: 100;
  line-height: 24px;
  text-align: left;
  list-style-type: disc;
}

.privacy-text a {
  color: #000;
}
</style>
