<template>
  <div :class="fixed ? 'fixedFooter' : 'footer'">
    <div class="footer__inner footer__imprint">
      <router-link :to="{ name: 'imprint' }">imprint</router-link>
      <router-link :to="{ name: 'privacy' }">privacy policy</router-link>
      <router-link :to="{ name: 'job' }">jobs</router-link>
      <router-link :to="{ name: 'presskit' }">presskit</router-link>
    </div>
    <div class="footer__inner footer__copyright">
      <p>&copy; 2021 black cave entertainment UG</p>
    </div>
    <div class="footer__inner footer__social">
      <a
        href="https://www.facebook.com/blackcave.ent"
        title="facebook"
        target="_blank"
        ><font-awesome-icon :icon="['fab', 'facebook']"></font-awesome-icon
      ></a>
      <a
        href="https://www.instagram.com/blackcave_ent/"
        title="instagram"
        target="_blank"
        ><font-awesome-icon :icon="['fab', 'instagram']"></font-awesome-icon
      ></a>
      <a
        href="https://twitter.com/blackcave_ent"
        title="twitter"
        target="_blank"
        ><font-awesome-icon :icon="['fab', 'twitter']"></font-awesome-icon
      ></a>
      <a
        href="https://de.linkedin.com/company/black-cave"
        title="twitter"
        target="_blank"
        ><font-awesome-icon :icon="['fab', 'linkedin']"></font-awesome-icon
      ></a>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer",
  props: {
    fixed: { type: Boolean, default: false },
  }  
};
</script>

<style scoped>

.footer {
  background-color: #000;
  width: 100%;
  padding: 32px 8px;
  /*position: fixed;*/
  /*bottom: 0;*/
  /*left: 0;*/
  z-index: 10;
  text-align: center;
  color: #fff;

  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  /*margin: 64px auto 0;*/
}

.fixedFooter {
  background-color: #000;
  width: 100%;
  padding: 32px 8px;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 10;
  text-align: center;
  color: #fff;

  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  /*margin: 64px auto 0;*/
}

.footer__inner {
  min-width: 400px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.footer__social {
  min-width: 200px;
}

.footer__inner a {
  text-decoration: none;
  font-size: 32px;
  line-height: 32px;
  color: #969696;
}

.footer__imprint {
  margin-bottom: 16px;
}

.footer__copyright {
  margin-bottom: 32px;
}

.footer__imprint a {
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: #fff;
}

.footer__copyright p {
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: #969696;
}
</style>
