import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Imprint from '../views/Imprint.vue'
import Privacy from '../views/Privacy.vue'
import Job from '../views/Job.vue'
import Presskit from '../views/Presskit.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/imprint',
    name: 'imprint',
    component: Imprint
  },
  {
    path: '/privacy',
    name: 'privacy',
    component: Privacy
  },
  {
    path: '/job',
    name: 'job',
    component: Job
  },
  {
    path: '/presskit',
    name: 'presskit',
    component: Presskit
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.afterEach((to) => {
  if(to.hash === "") {
    window.scrollTo(0, 0);
  }
});

export default router
