<template>
  <a class="anchor" id="game"></a>
  <section class="home__gameinfo">
    <h2 class="home__gameinfo-headline">game</h2>
    <div class="home__content-box" id="gamesecond">
      <div class="home__gameinfo-column">
        <p class="home__gameinfo-title">World</p>
        <p class="home__gameinfo-text">
          Antrum is a gigantic cave biotope in which human-like animals, to wild
          creatures and elemental monsters live. This world is not only
          characterised by its five major regions: Desert, Forest, Mountain and
          Sea regions, as well as the flying city of Haven, it is also split by
          the deep gap between the five major races, which is ever increasing
          due to racism and enslavement by the bird race in Haven.<br />
          The five major races of this world are the birds in Haven, the fish in
          the lake, the bears in the forest, the capricorns in the mountain and
          the hyenas in the desert. The regions are due to the mysterious light
          conditions within the cave world. The light comes through a
          luminescent, transparent crystal on the cave ceiling, which does not
          distribute the light rays evenly throughout the cave, thus creating
          different climatic zones.<br /><br />
        </p>
        <p class="home__gameinfo-title">Part I</p>
        <p class="home__gameinfo-text">
          In inAntrum - Part I The player is immersed in a world where not
          everything is as beautiful and peaceful as it seems. The protagonist
          Fynn, a Hailady from Groundbed, is looking for a way to the capital
          Haven. The player sneaks into the flying capital Haven and tries to
          free their captured pirate comrades, who were captured during the last
          attack of the Shark Pirates on the flying island, from the hands of
          the corrupt upper class of the bird race. In her journey, Fynn is
          confronted with many obstacles, secrets and riddles that must be
          discovered and solved. She must overcome her own hatred of society as
          well as the prejudices of the city's inhabitants towards her and reach
          into the deepest abysses of the city to save her comrades.<br />
          The Shark Pirates are a grouping of criminals and refugees from the
          Groundbed races. The Groundbed is the entire bottom of the massive
          caveworld, which includes all regions except the flying city of Haven.
        </p>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "GameSecond",
};
</script>

<style>
.home__gameinfo {
  max-width: 1280px;
  margin: 32px auto 0;
  padding: 0 16px;
}

.home__gameinfo-headline {
  color: #000000;
  font-weight: 400;
  font-size: 24px;
  line-height: 24px;
  margin-bottom: -4px;
  margin-left: 16px;
}

.home__gameinfo-text {
  font-size: 18px;
  font-weight: 100;
  line-height: 24px;
  text-align: justify;
  break-after: always;
}

.home__gameinfo-column {
  column-width: 400px;
  column-gap: 32px;
}

.home__gameinfo-title {
  column-width: 400px;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
}
</style>
