<template>
  <div class="nav">
    <div class="nav__inner">
      <router-link :to="{ name: 'home'}" class="nav__logo--mobile nav__logo--left"><img class="nav__logo" src="../assets/mobile/icon_logo_mobile.png" /></router-link>
      <router-link :to="{ name: 'home'}" class="nav__logo--mobile"><img class="nav__logo" src="../assets/mobile/header_logoText_mobile.png" /></router-link>
      <a href="#contact" class="nav__logo--mobile nav__logo--right" v-smooth-scroll><img class="nav__logo" src="../assets/mobile/icon_contact_mobile.png" /></a>
      <router-link :to="{ name: 'home'}" class="nav__logo--desktop"><img class="nav__logo" src="../assets/icon_logo_desktop.png" /></router-link>
      <div class="nav__links" v-if="$router.currentRoute.value.name === 'home'">
        <a class="nav__link--desktop" href="#game" v-smooth-scroll>Game</a>
        <a class="nav__link--desktop" href="#team" v-smooth-scroll>Team</a>
        <a class="nav__link--desktop" href="#studio" v-smooth-scroll>Studio</a>
        <a class="nav__link--desktop" href="#contact" v-smooth-scroll>Contact</a>
      </div>
      <div class="nav__links" v-if="$router.currentRoute.value.name !== 'home'">
        <router-link :to="{ name: 'home'}" class="nav__link--desktop">Home</router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Navigation'
}
</script>

<style>

.nav {
  background-color: #000;
  width: 100%;
  padding: 8px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  height: 80px;
}

.nav__inner {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  max-width: 1280px;
  align-items: center;
  margin: 0 auto;
}

.nav__logo {
  height: 36px;
}

.nav__logo--desktop {
  display: none;
}

.nav__logo--left {
  margin-left: 8px;
}

.nav__logo--right {
  margin-right: 8px;
}

.nav__links {
  display: none;
}

.nav__link--desktop {
  display: none;
}

@media screen and (min-width: 750px) {
  .nav__logo {
    height: 48px;
  }

  .nav__logo--mobile {
    display: none;
  }

  .nav__logo--desktop {
    display: block;
    margin-left: 16px;
  }

  .nav__links {
    display: flex;
    flex-direction: row;
  }

  .nav__link--desktop {
    display: block;
    margin-right: 16px;
  }
}

.nav a {
  color: #969696;
  text-decoration: none;
  font-size: 16px;
  font-weight: 400;
  text-transform: uppercase;
}

.nav a.router-link-exact-active {
  color: #1673D0;
  font-weight: 700;
}
</style>
