<template>
  <a class="anchor" id="team"></a>
  <section class="home__team">
    <h2 class="home__team-headline">team</h2>
    <div class="home__team-portraits home__content-box home__content-box--closer">
      <div class="portrait">
        <img class="image" src="../assets/team_marina.png" alt="Marina Hahn">
        <h3 class="name">Marina Hahn</h3>
        <hr />
        <p class="job">Founder | Managing Director</p>
        <p class="job">3D Character Artist</p>
        <p class="linkedIn">
          <a href="https://www.linkedin.com/in/marina-hahn-523660106/" title="Marina on LinkedIn" target="_blank"><span><font-awesome-icon :icon="['fab', 'linkedin']"></font-awesome-icon></span></a>
          <a href="#" title="Send E-Mail to Marina" @click.prevent="mailToMarina"><span><font-awesome-icon :icon="['fa', 'envelope']"></font-awesome-icon></span></a>
        </p>
      </div>
      <div class="portrait">
        <img class="image" src="../assets/team_dominik.png" alt="Dominik Blitsch">
        <h3 class="name">Dominik Blitsch</h3>
        <hr />
        <p class="job">Founder | Technical Director</p>
        <p class="job">UE4 Developer</p>
        <p class="linkedIn">
          <a href="https://www.linkedin.com/in/dominik-blitsch-a8b4131a6/" title="Dominik on LinkedIn" target="_blank"><span><font-awesome-icon :icon="['fab', 'linkedin']"></font-awesome-icon></span></a>
          <a href="#" title="Send E-Mail to Dominik" @click.prevent="mailToDominik"><span><font-awesome-icon :icon="['fa', 'envelope']"></font-awesome-icon></span></a>
        </p>
      </div>
      <div class="portrait">
        <img class="image" src="../assets/team_felix.png" alt="Dominik Blitsch">
        <h3 class="name">Felix van Hasseln</h3>
        <hr />
        <p class="job">Founder | Creative Director</p>
        <p class="job">3D Tech-Artist</p>
        <p class="linkedIn">
          <a href="https://www.linkedin.com/in/felix-van-hasseln-b448b5167/" title="Felix on LinkedInd" target="_blank"><span><font-awesome-icon :icon="['fab', 'linkedin']"></font-awesome-icon></span></a>
          <a href="#" title="Send E-Mail to Felix" @click.prevent="mailToFelix"><span><font-awesome-icon :icon="['fa', 'envelope']"></font-awesome-icon></span></a>
        </p>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Team',
  methods: {
    mailToMarina: function () {
      window.location.href = "mailto:hahn@black-cave.com";
    },
    mailToDominik: function () {
      window.location.href = "mailto:blitsch@black-cave.com";
    },
    mailToFelix: function () {
      window.location.href = "mailto:vanhasseln@black-cave.com";
    }
  }
}
</script>

<style>

.home__team {
  max-width: 1280px;
  margin: 32px auto 0;
  padding: 0 16px;
}

.home__team-headline {
  color: #000;
  font-weight: 400;
  font-size: 24px;
  line-height: 24px;
  margin-bottom: -4px;
  margin-left: 16px;
}

.home__team-portraits {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.portrait {
  flex: 100%;
  margin-bottom: 32px;
  text-align: center;
}

.portrait:last-child {
  margin-bottom: 0;
}

@media screen and (min-width: 642px) {
  .portrait {
    flex: 50%;
  }
}

@media screen and (min-width: 930px) {
  .portrait {
    flex: 33.3333%;
    margin-bottom: 0;
  }
}

.image {
  width: 250px;
  margin-bottom: 12px;
}

.name {
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
}

.job {
  font-weight: 200;
  font-size: 18px;
  line-height: 24px;
}

hr {
  width: 260px;
  height: 1px;
  border: none;
  background-color: #969696;
  margin: 16px auto 8px;
}

.linkedIn {
  font-weight: 200;
  font-size: 18px;
  line-height: 24px;
  margin-top: 8px;
}

.linkedIn a {
  text-decoration: none;
  color: #000;
}

.linkedIn a:last-child {
  margin-left: 8px;
}

.linkedIn span {
  font-size: 22px;
}

</style>
