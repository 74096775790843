<template>
  <section class="home__support">
    <h2 class="home__support-headline">supported by</h2>
    <div class="home__content-box">
      <img src="../assets/supported_logo_mediengruenderzentrumNRW.png" class="desktop" alt="Medien Gründer Zentrum NRW">
      <img src="../assets/supported_logo_gruenderstipendiumNRW.png" class="desktop" alt="Gründer Stipendium NRW">
      <img src="../assets/supported_logo_filmundmedienstiftungNRW.png" class="desktop" alt="Film- und Medienstiftung NRW">
      <img src="../assets/mobile/supported_logos_mobile.png" class="mobile" alt="Medien Gründer Zentrum NRW and Gründer Stipendium NRW">
    </div>
  </section>
</template>

<script>
export default {
  name: 'Support'
}
</script>

<style>

.home__support {
  max-width: 1280px;
  margin: 32px auto 0;
  padding: 0 16px;
}

.home__support-headline {
  color: #000000;
  font-weight: 400;
  font-size: 24px;
  line-height: 24px;
  margin-bottom: -4px;
  margin-left: 16px;
}

.home__support .home__content-box {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  padding-top: 32px;
  padding-bottom: 32px;
}

.home__support img.desktop {
  display: none;
}

.home__support img.mobile {
  display: block;
  width: 90%;
}

@media screen and (min-width: 800px) {
  .home__support img.desktop {
    display: block;
    width: 40%;
    max-width: 300px;
    padding-left: 5px;
    padding-right: 5px;
  }

  .home__support img.mobile {
    display: none;
  }
}
</style>
