<template>
  <div class="contact">
    <Navigation />
    <section class="job">
      <h2 class="job-headline">jobs</h2>
      <div class="content-box">
        <p class="job-text">
          We are looking for talented young people to support us in the
          development of our prototype for our first 3D action adventure RPG -
          inAntrum. If you’re interested in joining our team, please send us
          your German application.
        </p>
        <h3 class="job-sectionHeadline"><br />Open positions:</h3>
        <ul class="job-list">
          <li class="job-listItem">
            3D Environment Artist (m/f/d) - Freelancer<br />
            <a
              href="https://www.black-cave.com/jobs/BlackCave_3DEnvironmentArtist.pdf"
              title="Job_3DArtist"
              target="_blank"
              >job description</a
            >
          </li>
        </ul>
      </div>
    </section>
    <Footer fixed/>
  </div>
</template>

<script>
import Navigation from "@/components/Navigation";
import Footer from "@/components/Footer";

export default {
  name: "job",
  components: {
    Footer,
    Navigation,
  },
};
</script>

<style>
.content-box {
  background-color: #f6f6f6;
  padding: 64px 64px;
}

.content-box--fake {
  margin: 0 16px;
  padding: 8px 0;
}

.job {
  max-width: 1280px;
  margin: 32px auto;
  padding: 0 16px;
}

.job-headline {
  color: #000000;
  font-weight: 400;
  font-size: 24px;
  line-height: 24px;
  margin-bottom: -4px;
  margin-left: 16px;
}

.job-sectionHeadline {
  color: #000000;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 16px;
}

.job-text {
  font-size: 18px;
  font-weight: 100;
  line-height: 24px;
  text-align: left;
  margin-bottom: 16px;
}


.job-list {
  margin-left: 32px;
  margin-bottom: 100px;
}

.job-listItem {
  font-size: 18px;
  font-weight: 100;
  line-height: 24px;
  text-align: left;
  list-style-type: disc;
  margin-bottom: 16px;
}
.job-listItem a {
  color: #000;
}
</style>
