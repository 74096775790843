<template>
  <div class="contact">
    <Navigation />
    <section class="presskit">
      <h2 class="presskit-headline">presskit</h2>
      <div class="content-box">
        <p class="presskit-text">
          Here you can download our presskit.<br />
          The game itself can be downloaded via Steam.
        </p>
        <a
          class="ext-link"
          href="https://www.black-cave.com/presskit/inAntrum_Presskit_2022.zip"
          title="presskit"
          target="_blank"
          >Download Presskit</a
        ><br />
        <a
          class="ext-link"
          href="https://store.steampowered.com/app/973690/Antrum/"
          title="steam"
          target="_blank"
          >inAntrum on Steam</a
        >
      </div>
    </section>
    <Footer fixed/>
  </div>
</template>

<script>
import Navigation from "@/components/Navigation";
import Footer from "@/components/Footer";

export default {
  name: "presskit",
  components: {
    Footer,
    Navigation,
  },
};
</script>

<style>
.content-box {
  background-color: #f6f6f6;
  padding: 64px 64px;
}

.content-box--fake {
  margin: 0 16px;
  padding: 8px 0;
}

.presskit {
  max-width: 1280px;
  margin: 32px auto;
  padding: 0 16px;
}

.presskit-headline {
  color: #000000;
  font-weight: 400;
  font-size: 24px;
  line-height: 24px;
  margin-bottom: -4px;
  margin-left: 16px;
}

.presskit-sectionHeadline {
  color: #000000;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 16px;
}

.presskit-text {
  font-size: 18px;
  font-weight: 100;
  line-height: 24px;
  text-align: left;
  margin-bottom: 16px;
}

.ext-link {
  font-size: 18px;
  font-weight: 100;
  line-height: 24px;
  text-align: left;
  list-style-type: disc;
  margin-bottom: 16px;
  color: #000;
}
</style>
