<template>
  <section class="home__intro">
    <Carousel :wrapAround="true">
      <Slide v-for="slide in 4" :key="slide">
        <div class="carousel__item" :class="'carousel__item-' + slide"></div>
      </Slide>

      <template #addons>
        <Navigation />
      </template>
    </Carousel>
    <a
      href="https://store.steampowered.com/app/1718700/inAntrum/"
      target="_blank"
    >
      <img
        class="steam__button"
        src="../assets/banner_button.png"
        alt="Steam-Link"
      />
    </a>
  </section>
</template>

<script>
import { Carousel, Navigation, Slide } from "vue3-carousel";

export default {
  name: "Intro",
  components: {
    Carousel,
    Slide,
    Navigation,
  },
};
</script>

<style>
.home__intro {
  margin: 0 auto;
  max-width: 100%;
}

.carousel__viewport,
.carousel__track,
.carousel__slide {
  height: 50vh;
}

@media screen and (min-width: 960px) {
  .carousel__viewport,
  .carousel__track,
  .carousel__slide {
    height: 700px;
  }
}

.carousel__item {
  height: 100%;
  width: 100%;
}

.carousel__item-1 {
  background: url("../assets/banner/banner_image_07_desktop.jpg") no-repeat
    center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.carousel__item-2 {
  background: url("../assets/banner/banner_image_08_desktop.jpg") no-repeat
    center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.carousel__item-3 {
  background: url("../assets/banner/banner_image_11_desktop.jpg") no-repeat
    center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.carousel__item-4 {
  background: url("../assets/banner/banner_image_13_desktop.jpg") no-repeat
    center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.carousel__slide {
  padding: 0;
}

.steam__button {
  position: absolute;
  top: 40vh;
  right: 10%;
  width: 400px;
  height: auto;
}

@media screen and (min-width: 960px) {
  .steam__button {
    top: 580px;
  }
}

@media screen and (max-width: 480px) {
  .steam__button {
    width: 50%;
    top: 47vh;
    right: 25%;
  }
}

.carousel__prev,
.carousel__next {
  box-sizing: content-box;
  width: 5em;
  height: 5em;
  background-color: transparent;
  opacity: 0.4;
  transition: opacity 500ms ease-out;
}

.carousel__prev:hover,
.carousel__next:hover {
  opacity: 1;
}

.carousel__prev {
  left: 52px;
}

.carousel__next {
  right: 52px;
}

.carousel__icon {
  width: 5em;
  height: 5em;
}
</style>
