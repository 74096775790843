<template>
  <div class="home">
    <a class="anchor" id="home"></a>
    <Navigation />
    <Intro />
    <Game />
    <Updates />
    <GameSecond />
    <Team />
    <Studio />
    <Support />
    <Contact />
    <Footer />
  </div>
</template>

<script>
import 'vue3-carousel/dist/carousel.css';
import Navigation from "@/components/Navigation";
import Footer from "@/components/Footer";
import Intro from "@/components/Intro";
import Game from "@/components/Game";
import GameSecond from "@/components/GameSecond"
import Updates from "@/components/Updates";
import Studio from "@/components/Studio";
import Team from "@/components/Team";
import Support from "@/components/Support";
import Contact from "@/components/Contact";

export default {
  name: 'Home',
  components: {
    Contact,
    Support,
    Studio,
    Team,
    GameSecond,
    Updates,
    Game,
    Intro,
    Footer,
    Navigation
  }
}
</script>

<style>
.home__content-box {
  background-color: #F6F6F6;
  padding: 32px 64px;
}

.home__content-box--fake {
  margin: 0 32px;
  padding: 16px 0;
}

@media screen and (max-width: 1280px) {
  .home__content-box--fake {
    margin: 0 16px;
    padding: 16px 0;
  }
}

@media screen and (min-width: 1280px) {

  .home__content-box.home__content-box--closer {
    padding: 32px 64px;
  }
}

a.anchor {
  display: block;
  position: relative;
  top: -80px;
  visibility: hidden;
}
</style>
