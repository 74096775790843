<template>
  <a class="anchor" id="updates"></a>
  <section class="home__updates">
    <h2 class="home__updates-headline">updates</h2>
    <div class="home__content-box home__content-box--fake"></div>
    <div class="home__update-view">
      <img src="../assets/updates/update_12_preview.jpg" @click="openUpdateViewer(16)">
      <img src="../assets/updates/update_13_preview.jpg" @click="openUpdateViewer(15)">
      <img src="../assets/updates/update_16_preview.jpg" @click="openUpdateViewer(14)">
      <img src="../assets/updates/update_15_preview.jpg" @click="openUpdateViewer(13)">
      <img src="../assets/updates/update_11_preview.jpg" @click="openUpdateViewer(12)">
      <img src="../assets/updates/update_10_preview.jpg" @click="openUpdateViewer(11)">
      <img src="../assets/updates/update_17_preview.jpg" @click="openUpdateViewer(10)">
      <img src="../assets/updates/update_08b_preview.jpg" @click="openUpdateViewer(9)">
      <img src="../assets/updates/update_08_preview.jpg" @click="openUpdateViewer(8)">
      <img src="../assets/updates/update_07_preview.jpg" @click="openUpdateViewer(7)">
      <img src="../assets/updates/update_06_preview.jpg" @click="openUpdateViewer(6)">
      <img src="../assets/updates/update_05_preview.jpg" @click="openUpdateViewer(5)">
      <img src="../assets/updates/update_04_preview.jpg" @click="openUpdateViewer(4)">
      <img src="../assets/updates/update_03_preview.jpg" @click="openUpdateViewer(3)">
      <img src="../assets/updates/update_02_preview.jpg" @click="openUpdateViewer(2)">
      <img src="../assets/updates/update_01_preview.jpg" @click="openUpdateViewer(1)">
      <transition name="fade">
        <UpdateViewer v-if="showUpdateViewer" :updateId="updateId" @closeViewer="showUpdateViewer = false"/>
      </transition>
    </div>
    <div class="home__content-box home__content-box--fake"></div>
  </section>
</template>

<script>
import UpdateViewer from "@/components/UpdateViewer";
export default {
  name: 'Updates',
  components: {UpdateViewer},
  data: function() {
    return {
      updateId: 0,
      showUpdateViewer: false
    }
  },
  methods: {
    openUpdateViewer: function (updateId) {
      this.updateId = updateId;
      this.showUpdateViewer = true;
    }
  }
}
</script>

<style>

.home__updates {
  display: block;
  margin: 32px auto 0;
  max-width: 1312px;
}

.home__updates-headline {
  color: #000000;
  font-weight: 400;
  font-size: 24px;
  line-height: 24px;
  margin-bottom: -4px;
  margin-left: 48px;
}

@media screen and (max-width: 1280px) {
  .home__updates-headline {
    margin-left: 32px;
  }
}

.home__update-view {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.home__update-view img {
  width: 50%;
  flex: 50%;
}

.home__update-view img:hover {
  cursor: pointer;
}

@media screen and (min-width: 700px) {
  .home__update-view img {
    width: 25%;
    flex: 25%;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

</style>
