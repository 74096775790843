<template>
  <div class="contact" id="contact">
    <h3>get in touch!</h3>
    <p>Feel free to contact us directly via mail</p>
    <p><a href="#" title="Click here to send e-mail" @click.prevent="mailToBlackCave">info [at] black-cave.com</a></p>
  </div>
</template>

<script>
export default {
  name: 'Contact',
  methods: {
    mailToBlackCave: function () {
      window.location.href = "mailto:info@black-cave.com";
    }
  }
}
</script>

<style scoped>
.contact {
  background-color: #F6F6F6;
  width: 100%;
  padding: 32px 8px;
  margin: 54px auto 0;
  z-index: 10;
  text-align: center;
}

.contact h3 {
  font-size: 24px;
  line-height: 32px;
  font-weight: 400;
  margin-bottom: 4px;
}

.contact p {
  font-size: 18px;
  line-height: 24px;
  font-weight: 200;
}

.contact a {
  text-decoration: none;
  color: #000;
}
</style>
