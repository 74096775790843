<template>
  <a class="anchor" id="studio"></a>
  <section class="home__studio">
    <h2 class="home__studio-headline">studio</h2>
    <div class="home__content-box">
      <p class="home__studio-text">
        We at Black Cave Entertainment are an upcoming developer studio with focus on story-based role-playing games, with immersive stories, creative gameplay and advanced 3D graphics to offer the customer the best possible gaming experience. Therefore, we use the Unreal Engine 4/5, one of the most successful/newest developer interfaces, in order to be able to master the technical and innovative challenges we have set ourselves during development. In order to achieve our goals, we would like to build a development studio that offers us and our employees financial security and at the same time enables us to develop games that we want to create.
      </p>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Studio'
}
</script>

<style>
.home__studio {
  max-width: 1280px;
  margin: 32px auto 0;
  padding: 0 16px;
}

.home__studio-headline {
  color: #000000;
  font-weight: 400;
  font-size: 24px;
  line-height: 24px;
  margin-bottom: -4px;
  margin-left: 16px;
}

.home__studio-text {
  column-width: 400px;
  font-size: 18px;
  font-weight: 100;
  line-height: 24px;
  text-align: justify;
  column-gap: 32px;
}

.home__studio-vision {
  padding: 8px 32px;
  font-size: 18px;
  font-weight: 400;
  font-style: italic;
  text-align: center;
}
</style>
