<template>
  <a class="anchor" id="game"></a>
  <section class="home__message">
    <h2 class="home__message-headline">inAntrum</h2>
    <div class="home__content-box">
      <p class="home__message-text">
        inAntrum is an atmospheric 3D action-adventure RPG developed as game
        series and released in five parts. It combines action RPG elements from
        The Witcher III, like the combat and quest system, as well as adventure
        puzzle elements and a 3D-stylised graphic from the Zelda series in an
        entirely new fantasy setting. The game is being developed in Unreal
        engine 4 for the PC first.
      </p>
    </div>
  </section>
</template>

<script>
export default {
  name: "Game",
};
</script>

<style>
.home__message {
  max-width: 1280px;
  margin: 32px auto 0;
  padding: 0 16px;
}

.home__message-headline {
  color: #000000;
  font-weight: 400;
  font-size: 24px;
  line-height: 24px;
  margin-bottom: -4px;
  margin-left: 16px;
}

.home__message-text {
  column-width: 400px;
  font-size: 18px;
  font-weight: 100;
  line-height: 24px;
  text-align: justify;
  column-gap: 32px;
}

.home__message-title {
  column-width: 400px;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
}
</style>
